import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import Quote from "../components/projectquote";
import GalleryItem from "../components/galleryitem";
import ShopifyCollection from "../components/shopifycollection";
import Footer from "../components/footer";
import Header from "../components/projectheader";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

import styleProject from "../components/projects.module.css";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.projectsJson.name} - No Goblin</title>
        <link rel="canonical" href="https://nogoblin.com" />
        <meta property="og:title" content={data.projectsJson.name} />
        <meta property="og:description" content={data.projectsJson.selltext_short} />
        <meta property="og:url" content={"https://nogoblin.com/" + data.projectsJson.urlslug} />
        <meta property="og:image" content="https://nogoblin.com/ms-icon-310x310.png" />
        <meta property="og:site_name" content="No Goblin" />
        <meta property="og:type" content="object" />
      </Helmet>

      <div className={styleProject.banner}>
        <BackgroundImage
          fluid={data.projectsJson.frontpage_keyart.childImageSharp.fluid}
          title={data.projectsJson.name}
          className={styleProject.masterContainer}
        >
          <Header twitter={data.projectsJson.twitter} />

          <div className={styleProject.logoContainer}>
            <Img
              fluid={data.projectsJson.logo.childImageSharp.fluid}
              alt={data.projectsJson.name}
              className={styleProject.logo}
            />
          </div>
        </BackgroundImage>
      </div>

      <center>
        <div className={styleProject.downstreamContainer}>
          <div className={styleProject.capsule + " " + styleProject.wrapper}>
            <div className={(data.projectsJson.quotes.length > 0) ? styleProject.cellLeft : styleProject.cellAll}>
              {data.projectsJson.selltext.split("\n").map(function(item, key) {
                return (
                  <span key={key}>
                    {item}
                    <br />
                    <br />
                  </span>
                );
              })}
              <div>
               
                <Link to={"faq/"}>
                <h3>📕{" "}Read the full {data.projectsJson.name} FAQ!</h3>
                </Link>
              </div>
            </div>

            
            <div className={styleProject.cellRight}>
              {data.projectsJson.quotes.map((quote, index) => (
                <div>
                  <Quote key={index} quoteData={quote} />
                </div>
              ))}
            </div>
          </div>

        {data.projectsJson.feature_video && 
          <div className={styleProject.capsule + " " + styleProject.aspectRatio}>
            <iframe
 
              src={data.projectsJson.feature_video}
              allowFullScreen=""
              frameBorder="0"
            />
          </div>
        }

          <div className={styleProject.capsule + " " + styleProject.wrapper}>
            <div className={(data.projectsJson.buy_embed ? styleProject.buyEmbed : styleProject.cellNone)}>
              <iframe
                src={data.projectsJson.buy_embed}

                scrolling="no"
                frameBorder="0"
              />
            </div>

            <div className={(data.projectsJson.buy_embed) ? styleProject.buyLinks : styleProject.cellAll}>
              <h3>{data.projectsJson.name} is {data.projectsJson.buy_embed ? "also" : ""} available on</h3>
              {data.projectsJson.buy_alt.map((buy, index) => (
                <div>
                  <h3>
                    <a href={buy.url}>{buy.name}</a>
                  </h3>
                </div>
              ))}
            </div>
          </div>

          <div className={styleProject.capsule}>
            <div className={styleProject.galleryContainer}>
              {data.projectsJson.gallery.map((item, index) => (
                <GalleryItem key={index} itemData={item} />
              ))}
            </div>
            <span />
          </div>

          <div className={styleProject.capsule}>
            <ShopifyCollection />
          </div>
        </div>
        <Footer />
      </center>
    </Layout>
  );
};

// The post template's GraphQL query. Notice the “id”
// variable which is passed in. We set this on the page
// context in gatsby-node.js.
//
// All GraphQL queries in Gatsby are run at build-time and
// loaded as plain JSON files so have minimal client cost.
export const pageQuery = graphql`
  query ProjectQuery($urlslug: String!) {
    # Select the post which equals this id.
    projectsJson(urlslug: { eq: $urlslug }) {
      id
      name
      urlslug
      selltext
      selltext_short
      twitter

      feature_video
      quotes {
        quote
        author
        link
      }

      buy_embed
      buy_alt {
        name
        url
      }

      gallery {
        image {
          childImageSharp {
            thumb: fluid(maxWidth: 320, toFormat:JPG) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }

            full: fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }

      frontpage
      frontpage_keyart {
        childImageSharp {
          fluid(maxWidth:1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      logo {
        childImageSharp {
          fluid(maxHeight:512) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  }
`;
